import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import SmallerNav from "./smallerNav"
import Share from "../CompfestTalksPage/Share"
import Player from "./player"

export default ({ channelKey }) => {
  const { innovation, influence } = useStaticQuery(graphql`
    {
      innovation: allContentfulCompfestTalksRundown(
        filter: {
          channel: {
            in: [
              "Live at Innovation Channel"
              "Live at Innovation Channel & Influence Channel"
            ]
          }
        }
        sort: { fields: time }
      ) {
        nodes {
          name
          time
          channel
          day {
            day
          }
        }
      }
      influence: allContentfulCompfestTalksRundown(
        filter: {
          channel: {
            in: [
              "Live at Influence Channel"
              "Live at Innovation Channel & Influence Channel"
            ]
          }
        }
        sort: { fields: time }
      ) {
        nodes {
          name
          time
          channel
          day {
            day
          }
        }
      }
    }
  `)

  const streams = [
    {
      title: "INNOVATION",
      component: (
        <Player
          url="https://www.youtube.com/embed/Q2xhVrwcdn4"
          rundownData={innovation}
        />
      ),
    },
    {
      title: "INFLUENCE",
      component: (
        <Player url="https://youtu.be/D1MNs3wCAPQ" rundownData={influence} />
      ),
    },
  ]
  return (
    <>
      <Header />
      <SmallerNav navItems={streams} selectedChannel={channelKey} />
      <Share />
    </>
  )
}
