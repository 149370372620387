import React from "react"
import styled from "styled-components"

import titleSvg from "../CompfestTalksPage/Header/compfest-talks-title.svg"

const HeaderStyle = styled.div`
  display: flex;
  flex-direction: column;
  aling-items: center;
  text-align: center;

  img {
    margin: 0 auto;

    &:first-child {
      margin: 96px auto 10px;
    }
    &.big-title {
      width: 100%;
      max-width: 610px;
    }
  }

  p.date,
  p.tagline {
    font-family: itc;
    font-weight: bold;
    font-size: 1.5rem;
  }
  p.date {
    margin: 24px 0 48px;
  }
  p.coming-soon {
    font-weight: bold;
    margin-bottom: 8px;
  }
  p.desc {
    margin-top: 85px;
  }
  p.tagline {
    margin: 12px 0 36px;
  }

  @media (max-width: 768px) {
    img:first-child {
      margin-top: 48px;
      width: 100%;
      max-width: 192px;
    }
    img.big-title {
      max-width: 343px;
    }
    p.tagline {
      margin-bottom: 32px;
    }
  }
`

// const START_DATE = new Date(2020, 9, 3, 0, 0, 0) // 3 October

export default () => {
  return (
    <HeaderStyle>
      <img className="big-title" src={titleSvg} alt="COMPFEST TALKS" />
      <p className="date">3 - 4 October 2020</p>
      {/* <p className="coming-soon">Coming soon in</p>
      <Countdown date={START_DATE} compfestTalks />
      <p className="desc">
        Numerous <b>virtual talk shows</b> on certain topics related to tech,
        takes on the theme
      </p>
      <p className="tagline">
        “Empowering Society through Technological Innovation”
      </p> */}
    </HeaderStyle>
  )
}
