import React from "react"
import styled from "styled-components"

import ReactPlayer from "react-player"

import useGetWindowWidth from "utils/useGetWindowWidth"
import Button from "components/Button"
import Rundown from "../CompfestTalksPage/Rundown/rundown"

const PlayerWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }

  .react-player__preview {
    background-color: #454545;
    background-blend-mode: overlay;
  }

  .react-player__shadow {
    background: #fd004e !important;
    padding: 0.75em;
  }

  @media (max-width: 768px) {
    .react-player__shadow {
      padding: 0.1em;
    }
  }
`

const RundownContainer = styled.div`
  font-family: itc;
  margin-top: 6em;

  h2 {
    margin-bottom: 1.5em;
    font-weight: bold;
    text-align: center;
  }
`

const StreamYoutubeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2em;
  flex-direction: column;

  button {
    font-family: "DM Sans";
    font-weight: bold;
    display: flex;
    align-items: center;

    .play-icon {
      margin-right: 0.6em;
    }
  }
`

const VideoPlayer = ({ url }) => {
  const isMobile = useGetWindowWidth() < 768

  const config = {
    youtube: {
      playerVars: {
        modestbranding: 1,
      },
    },
  }

  return (
    <>
      <PlayerWrapper>
        <ReactPlayer
          className="react-player"
          url={url}
          config={config}
          width="100%"
          height="100%"
          controls
          light
          playing
        />
      </PlayerWrapper>
      {isMobile && (
        <StreamYoutubeContainer>
          <Button href={url}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="play-icon"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM8.65527 7.8688V16.1311L16.9176 12.1032L8.65527 7.8688Z"
                fill="#F3F3F3"
              />
            </svg>
            Stream on Youtube
          </Button>
        </StreamYoutubeContainer>
      )}
    </>
  )
}

export default ({ url, rundownData }) => {
  const START_DATE = new Date(2020, 9, 4, 0, 0, 0) // 4 October
  const dayData = {
    nodes: rundownData.nodes.filter(
      (r) => r.day.day === (new Date() > START_DATE ? "Day 2" : "Day 1")
    ),
  }

  return (
    <>
      <VideoPlayer url={url} />
      <RundownContainer>
        <h2>CHANNEL RUNDOWN</h2>
        <Rundown data={dayData} />
      </RundownContainer>
    </>
  )
}
